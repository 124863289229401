import { CalculatorModule } from '@akebono/calculator';
import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NZ_I18N, ru_RU } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';

import { AlertsModule } from '../shared-components/alerts-list/alerts.module';
import { AutoLotsFilterModule } from '../shared-components/auto-lots-filter/auto-lots-filter.module';
import { DriverModule } from '../shared-components/driver/driver.module';
import { LotActionsModule } from '../shared-components/lot-actions/lot-actions.module';
import { LotAddPhotoButtonModule } from '../shared-components/lot-add-photo-button/lot-add-photo-button.module';
import { LotAuctionNameModule } from '../shared-components/lot-auction-name/lot-auction-name.module';
import { LotBidsModule } from '../shared-components/lot-bids/lot-bids.module';
import { LotBoundaryInfoModule } from '../shared-components/lot-boundary-info/lot-boundary-info.module';
import { LotCarouselModule } from '../shared-components/lot-carousel/lot-carousel.module';
import { LotCheckProductionDateModule } from '../shared-components/lot-check-production-date/lot-check-production-date.module';
import { LotCountersModule } from '../shared-components/lot-counters/lot-counters.module';
import { LotCreateTranslationRequestButtonModule } from '../shared-components/lot-create-translation-request-button/lot-create-translation-request-button.module';
import { LotEventsModalModule } from '../shared-components/lot-events-modal/lot-events-modal.module';
import { LotExtraInfoModule } from '../shared-components/lot-extra-info/lot-extra-info.module';
import { LotGetPhotoRequestButtonModule } from '../shared-components/lot-get-photo-request-button/lot-get-photo-request-button.module';
import { LotIconsModule } from '../shared-components/lot-icons/lot-icons.module';
import { LotInterestingModule } from '../shared-components/lot-interesting/lot-interesting.module';
import { LotLastVisitDateModule } from '../shared-components/lot-last-visit-date/lot-last-visit-date.module';
import { LotListDataServiceModule } from '../shared-components/lot-list-data-service/lot-list-data-service.module';
import { LotMainInfoModule } from '../shared-components/lot-main-info/lot-main-info.module';
import { LotMiniGalleryModule } from '../shared-components/lot-mini-gallery/lot-mini-gallery.module';
import { LotNotationsButtonModule } from '../shared-components/lot-notations-button/lot-notations-button.module';
import { LotPlaceBidButtonDumbModule } from '../shared-components/lot-place-bid-button-dumb/lot-place-bid-button-dumb.module';
import { LotPlaceBidButtonModule } from '../shared-components/lot-place-bid-button/lot-place-bid-button.module';
import { LotResetButtonModule } from '../shared-components/lot-reset-button/lot-reset-button.module';
import { LotSharedAddListButtonModule } from '../shared-components/lot-shared-add-list-button/lot-shared-add-list-button.module';
import { LotSharedIconModule } from '../shared-components/lot-shared-icon/lot-shared-icon.module';
import { LotSharedIncludesModule } from '../shared-components/lot-shared-includes/lot-shared-includes.module';
import { LotStatisticsModule } from '../shared-components/lot-statistics/lot-statistics.module';
import { LotTicketRulesModule } from '../shared-components/lot-ticket-rules/lot-ticket-rules.module';
import { LotTitleFullModule } from '../shared-components/lot-title-full/lot-title-full.module';
import { LotTranslateButtonModule } from '../shared-components/lot-translate-button/lot-translate-button.module';
import { LotTranslationsModule } from '../shared-components/lot-translations/lot-translations.module';
import { LotsListLotLinkModule } from '../shared-components/lots-list-lot-link/lots-list-lot-link.module';
import { PurchaseReportFormModalModule } from '../shared-components/purchase-report-form-modal/purchase-report-form-modal.module';
import { ShareButtonsModule } from '../shared-components/share-buttons/share-buttons.module';
import { TranslationTimeModule } from '../shared-components/translation-time/translation-time.module';
import { UserPurchaseStatisticsModule } from '../shared-components/user-purchase-statistics/user-purchase-statistics.module';
import { LotComponent } from './components/auto-lot/auto-lot.component';
import { ListComponent } from './components/auto-lots-list/auto-lots-list.component';
import { LotNoteModule } from '../shared-components/lot-note/lot-note.module';

@NgModule({
  declarations: [ListComponent, LotComponent],
  imports: [
    BrowserModule,
    AlertsModule,
    LotCreateTranslationRequestButtonModule,
    LotSharedAddListButtonModule,
    LotAddPhotoButtonModule,
    LotTranslationsModule,
    LotGetPhotoRequestButtonModule,
    LotNotationsButtonModule,
    LotExtraInfoModule,
    LotMainInfoModule,
    LotPlaceBidButtonModule,
    LotPlaceBidButtonDumbModule,
    LotCarouselModule,
    LotLastVisitDateModule,
    LotCheckProductionDateModule,
    LotMiniGalleryModule,
    LotStatisticsModule,
    LotTranslateButtonModule,
    LotIconsModule,
    LotActionsModule,
    LotAuctionNameModule,
    LotBoundaryInfoModule,
    AutoLotsFilterModule,
    LotInterestingModule,
    TranslateModule,
    FormsModule,
    HttpClientModule,
    PurchaseReportFormModalModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AkebonoCoreModule,
    LotsListLotLinkModule,
    CalculatorModule,
    NzCollapseModule,
    NzGridModule,
    NzTagModule,
    NzSelectModule,
    NzInputModule,
    NzDividerModule,
    NzTableModule,
    NzCarouselModule,
    NzDescriptionsModule,
    NzButtonModule,
    NzStatisticModule,
    NzAlertModule,
    NzFormModule,
    NzInputNumberModule,
    NzAvatarModule,
    NzTypographyModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzSkeletonModule,
    NzSpinModule,
    NzResultModule,
    NzSpaceModule,
    NzIconModule,
    LotSharedIconModule,
    ShareButtonsModule,
    LotSharedIncludesModule,
    LotEventsModalModule,
    LotBidsModule,
    LotTitleFullModule,
    LotCountersModule,
    LotResetButtonModule,
    LotTicketRulesModule,
    TranslationTimeModule,
    LotListDataServiceModule,
    UserPurchaseStatisticsModule,
    DriverModule,
    NzToolTipModule,
    LotNoteModule,
  ],
  providers: [
    {
      provide: NZ_I18N,
      useValue: ru_RU,
    },
  ],
  bootstrap: [],
})
export class AuctionsAutoModule {}
